
import Icon from "@/components/widgets/icons/Icon.vue"

import { Component, Prop, Vue, Emit } from "vue-property-decorator"

export const modalSizes = {
  small: "small",
  medium: "medium",
  large: "large",
  xlarge: "xlarge",
} as const

export type ModalSize = (typeof modalSizes)[keyof typeof modalSizes]

@Component({
  components: {
    Icon,
  },
})
export default class Modal extends Vue {
  @Prop()
  value!: boolean

  @Prop()
  title!: string

  @Prop()
  subtitle?: string

  @Prop()
  size?: ModalSize

  @Prop()
  showClose!: boolean
  @Prop({ default: false }) readonly persistent?: boolean

  @Emit("close")
  onClose() {
    return false
  }

  @Emit("input")
  onInput(value: any) {
    if (!value) this.onClose()

    return value
  }

  get modalWidth() {
    const map: Record<ModalSize, number> = {
      xlarge: 1200,
      small: 400,
      medium: 500,
      large: 800,
    }

    return map[this.size || "medium"]
  }
}
