import { Ref } from "vue"

export type ItemType<T> = T extends (infer U)[] ? U : never

export enum PageState {
  Loading = "loading",
  Loaded = "loaded",
  Error = "error",
  Empty = "empty",
}

type Icon = {
  icon: string
  backgroundColor: string
}

export type Icons = {
  "expand-operations": Icon
  "organize-corporate-event": Icon
  "due-diligence": Icon
  "execute-business-plan": Icon
  "improve-operations": Icon
  "technical-assistance": Icon
  "strategy-plan": Icon
  "subject-matter-expertise": Icon
  "support-operational-activities": Icon
  "pitch-deck-document": Icon
  default: Icon
}

export type Brand = "peralta" | "ahead"

export type Theme = {
  primaryColor: string
  secondaryColor?: string
  textColor?: string
  iconColor?: string
  [key: string]: string | undefined
}

export type ProvidedRefs<T> = {
  [K in keyof T]: Ref<T[K]>
}

export type Paginate = {
  page: number
  per: number
  length: number
}
