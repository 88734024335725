
import { VBtn } from "vuetify/lib"
import { VBtnToggle } from "vuetify/lib"
import { useUtilities } from "@/hooks/useUtilities"

import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    "v-btn": VBtn,
    "v-btn-toggle": VBtnToggle,
  },
})
export default class Tabs extends Vue {
  @Prop() readonly tabs!: { label: string; key: string; badge?: number }[]
  @Prop({ default: "#f5f6f7" }) readonly backgroundColor?: string

  @Prop({ default: true }) readonly updateParams?: boolean

  tabState = 0

  updateTabState(index: number) {
    this.tabState = index

    if (!this.updateParams) return
    const { updateSearchParams } = useUtilities()

    updateSearchParams({ key: "tab", value: this.tabs[index].key })
  }

  activeTab() {
    return this.$slots.default?.[this.tabState]
  }

  tools() {
    return this.$slots?.tools
  }

  searchParams = new URLSearchParams(window.location.search)

  mounted() {
    if (!this.updateParams) return
    const tab = this.searchParams.get("tab")
    if (tab) {
      this.tabState = this.tabs.findIndex((t) => t.key === tab)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(h: any) {
    return (
      <div>
        <div class="d-flex align-center justify-space-between ">
          <div
            style={{ background: this.backgroundColor }}
            class="tab-container d-flex align-center justify-center pa-2"
          >
            {this.tabs.map((tab, index) => (
              <button
                class={{ active: index === this.tabState }}
                onClick={() => this.updateTabState(index)}
              >
                {tab.label}
                {tab.badge && (
                  <span class="custom-badge d-flex align-center justify-center rounded-circle ml-2">
                    {tab.badge}
                  </span>
                )}
              </button>
            ))}
          </div>
          {this.tools && this.tools()}
        </div>
        {this.activeTab()}
      </div>
    )
  }
}
