import Vue from "vue"
import VueI18n from "vue-i18n"

export function loadLocaleMessages(): Record<string, any> {
  const context = require.context("./locales", true, /.json$/)

  return context.keys().reduce((messages: Record<string, any>, key) => {
    const name = key.replace("./", "").replace(".json", "")
    messages[name] = context(key)
    return messages
  }, {} as Record<string, any>)
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: "en",
  messages: {
    en: { ...loadLocaleMessages() },
  },
})
