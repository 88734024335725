var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.local)?_c('img',{style:({
    width: _vm.size + 'px',
    height: _vm.size + 'px',
  }),attrs:{"src":_setup.icon,"alt":_vm.name}}):_c(_setup.Icon,{style:({
    fontSize: _vm.size + 'px',
    color: _vm.color,
  }),attrs:{"icon":_setup.iconName}})
}
var staticRenderFns = []

export { render, staticRenderFns }