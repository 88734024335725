
import { Component, Prop, Vue, Emit } from "vue-property-decorator"
import { Theme } from "@/types"
import { THEMES } from "@/constants"
import Icon from "@/components/widgets/icons/Icon.vue"

@Component({
  components: {
    Icon,
  },
})
export default class ConfirmDialog extends Vue {
  @Prop() value!: boolean
  @Prop({ default: false }) readonly loading!: boolean
  @Prop({ default: false }) readonly disableConfirmIf?: boolean
  @Prop({ default: true }) readonly confirmButtonVisible?: boolean
  @Prop({ default: true }) readonly showActionButtons?: boolean
  @Prop({ default: false }) readonly persistent?: boolean
  @Prop()
  size?: "small" | "medium" | "large" | "xlarge"

  @Prop({ default: true }) readonly showCloseButton?: boolean

  @Prop({ default: "Confirm" })
  confirmText?: string

  @Prop({ default: "Are you sure?" }) title?: string

  @Prop({ default: "Cancel" })
  cancelText?: string

  @Prop({
    default: () => ({
      primaryColor: THEMES.PERALTA_PRIMARY,
    }),
  })
  theme?: Theme

  @Emit("action")
  @Emit("confirm")
  onConfirm() {
    return true
  }

  @Emit("action")
  @Emit("cancel")
  onCancel() {
    return false
  }

  @Emit("input")
  onInput(value: any) {
    if (!value) this.onCancel()

    return value
  }

  get modalWidth() {
    const map = {
      xlarge: 1200,
      small: 400,
      medium: 500,
      large: 800,
    }

    return map[this.size || "small"]
  }
}
